.feeling {
  flex-grow: 1;
  white-space: nowrap;
  position: relative;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0 0.3rem 0.6rem;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  outline-width: 1px;
  outline-style: solid;
  user-select: none;
  &:hover:not(.feelingSelected) {
    transition: all 0.5s;
    transform: translate3d(0, -2px, 0);
    box-shadow: 0px 4px 6px transparentize(black, 0.85);
  }
}

.feelingSelected {
  transform: translate3d(0, -2px, 0);
  box-shadow: 0px 4px 6px transparentize(black, 0.85);
}
